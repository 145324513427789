import { AxiosResponse } from 'axios'
import _ from 'lodash'
import moment from 'moment'

import { getChangeLogDataAPIPath } from 'config/apiPaths'
import { DATE_TIME_FORMAT, DEFAULT_PAGINATOR_DATA } from 'config/constants'
import { FIELD_TYPES, ORDER_BY } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IChangeLogData from 'interfaces/changeLog/IChangeLogData'
import IChangeLogFilter from 'interfaces/changeLog/IChangeLogFilter'
import IChangeLogFilterModal from 'interfaces/changeLog/IChangeLogFilterModal'
import IParamChangeLogData from 'interfaces/changeLog/params/IParamChangeLogData'
import IResponseChgLog from 'interfaces/changeLog/response/IResponseChgLog'
import IResponseChgLogField from 'interfaces/changeLog/response/IResponseChgLogField'
import IOption from 'interfaces/common/IOption'
import IPaginator from 'interfaces/common/IPaginator'
import IField from 'interfaces/field/IField'
import IFile from 'interfaces/file/IFile'
import ITable from 'interfaces/table/ITable'
import ITableRow from 'interfaces/table/ITableRow'
import AxiosService from 'lib/AxiosService'
import { getColumnData, getRowData } from 'pages/changeLog/Helper'
import { setChangeLogTableData, setError } from 'store/reducers/changeLogSlice'
import FormFieldHelper from 'utils/formField/FormFieldHelper'

export default class ChangeLogHelper {
  /**
   * Get URL parameters
   * @param {IChangeLogFilter}
   * @param {IChangeLogFilterModal}
   * @returns {string}
   */
  public static getUrlParams(
    { dateRange, sideModal }: IChangeLogFilter,
    filterModalData: IChangeLogFilterModal,
  ): string {
    const sDate: any = moment.utc(_.first(dateRange)).toISOString()
    const eDate: any = moment.utc(`${_.last(dateRange)} 23:59:59`).toISOString()
    let queryParams: string = `&createdAt%5Bgte%5D=${encodeURIComponent(sDate)}&createdAt%5Blte%5D=${encodeURIComponent(
      eDate,
    )}`

    const { selectedCategories, selectedFormFields, selectedUserField } = sideModal
    const { categories, formFields } = filterModalData

    let paramCategories: string = ''
    if (
      !_.isEqual(selectedCategories.length, categories.length) &&
      _.isEqual(selectedFormFields.length, formFields.length)
    ) {
      paramCategories = selectedCategories.map((category: ICategory) => `categoryId=${category.id}`).join('&')
      queryParams = `${queryParams}&${paramCategories}`
    }

    let paramFormFields: string = ''
    if (!_.isEqual(selectedFormFields.length, formFields.length)) {
      paramFormFields = selectedFormFields.map((field: IField) => `formFieldId=${field.formFieldId}`).join('&')
      queryParams = `${queryParams}&${paramFormFields}`
    }

    let paramUsers: string = ''
    if (!_.isEmpty(selectedUserField.value)) {
      paramUsers = selectedUserField.value.map((option: IOption) => `createdBy=${option.subLabel}`).join('&')
      queryParams = `${queryParams}&${paramUsers}`
    }
    return queryParams
  }
  /**
   * Get change log data
   * @param {string} accessToken
   * @param {IApp} app
   * @param {IChangeLogFilter} selectedFilterData
   * @param {IChangeLogFilterModal} filterModalData
   * @param {number} page
   * @param {string} projectQuestionnaireId
   * @param {ORDER_BY} sortOrder
   * @param {string} tenantId
   * @returns {Array<ICategory>}
   */
  public static async getChangeLogData({
    accessToken,
    app,
    selectedFilterData,
    filterModalData,
    page,
    projectQuestionnaireId,
    orderBy,
    tenantId,
  }: {
    accessToken: string
    projectQuestionnaireId: string
    app: IApp
    tenantId: string
    page: number
    orderBy: ORDER_BY
    selectedFilterData: IChangeLogFilter
    filterModalData: IChangeLogFilterModal
  }): Promise<{ rowData: Array<ITableRow>; paginator: IPaginator }> {
    const axiosService = new AxiosService(accessToken)
    const urlParams: string = this.getUrlParams(selectedFilterData, filterModalData)
    const response: AxiosResponse<IResponseChgLog> = await axiosService.get(
      getChangeLogDataAPIPath(projectQuestionnaireId, page, orderBy, urlParams),
      tenantId,
    )
    const data: {
      data: Array<IChangeLogData>
      paginator: IPaginator
    } = this.buildTableData(app, response.data)

    return {
      rowData: getRowData(app.categories, data.data),
      paginator: data.paginator,
    }
  }

  /**
   * Build table data
   * @param {IApp} app
   * @param {IResponseChgLog} tableData
   * @returns {Array<IChangeLogData>}
   */
  public static buildTableData(
    app: IApp,
    tableData: IResponseChgLog,
  ): {
    data: Array<IChangeLogData>
    paginator: IPaginator
  } {
    const formFieldHelper = new FormFieldHelper()

    const data: Array<IChangeLogData> = tableData.data.map((fieldData: IResponseChgLogField) => {
      const field: IField | null = formFieldHelper.findFieldByFormFieldId(app.categories, fieldData.formFieldId)
      const { newValue, oldValue } = this.getFieldValue(field, fieldData)

      const { categoryName, fieldName } = this.getFieldCatNameByFormFieldId(app, fieldData.formFieldId)
      return {
        id: fieldData.id,
        categoryName,
        date: fieldData.createdAt ? moment.utc(fieldData.createdAt).format(DATE_TIME_FORMAT) : '',
        fieldName,
        newValue,
        oldValue,
        userEmail: fieldData.createdBy,
      }
    })

    return {
      data,
      paginator: tableData.paginator,
    }
  }

  /**
   * Get file field value
   * @param {Array<IFile>} value
   * @returns {string}
   */
  public static getFileFieldValue(value: any): string {
    if (_.isArray(value)) {
      return value.map((file: IFile) => `<a href='${file.file.url}' target='_blank'>${file.file.name}</a>`).join(', ')
    }
    return ''
  }

  /**
   * Get table field value
   * @param {IField | null} field
   * @param {IResponseChgLogField} fieldData
   * @returns {{ newValue: string; oldValue: string }}
   */
  public static getFieldValue(
    field: IField | null,
    fieldData: IResponseChgLogField,
  ): { newValue: string; oldValue: string } {
    if (!field) return { newValue: '', oldValue: '' }

    if (_.isEqual(field.type, FIELD_TYPES.FILE_UPLOAD)) {
      return {
        newValue: this.getFileFieldValue(fieldData?.value?.value),
        oldValue: this.getFileFieldValue(fieldData.change?.value?.value),
      }
    }
    return { newValue: fieldData.displayValue, oldValue: fieldData.change ? fieldData.change.displayValue : '' }
  }

  /**
   * Get field and category name
   * @param {IApp} app
   * @param {string} formFieldId
   * @returns {{fieldName: string categoryName: string}}
   */
  public static getFieldCatNameByFormFieldId(
    app: IApp,
    formFieldId: string,
  ): {
    fieldName: string
    categoryName: string
  } {
    let fieldName: string | null = null
    for (let category of app.categories) {
      for (let form of category.forms) {
        for (let field of form.fields) {
          fieldName = this.getFieldNameById(field, formFieldId)
          if (!_.isNull(fieldName)) {
            return {
              fieldName,
              categoryName: category.name,
            }
          }
        }
      }
    }
    return {
      fieldName: '',
      categoryName: '',
    }
  }

  /**
   * Get field name by category id
   * @param {IField} field
   * @param {string} formFieldId
   * @returns {string}
   */
  private static getFieldNameById(field: IField, formFieldId: string): string | null {
    if (_.isEqual(field.formFieldId, formFieldId)) {
      return _.toString(field.fieldConfig.text)
    }

    for (let childField of field.children) {
      const fieldName = this.getFieldNameById(childField, formFieldId)
      if (!_.isNull(fieldName)) {
        return fieldName
      }
    }
    return null
  }

  /**
   * Get change log data
   * @param {interface} IParamChangeLogData
   * @returns {Promise<void>}
   */
  public static async getInitData({
    accessToken,
    app,
    orderBy,
    projectQuestionnaireId,
    tenantId,
    tableData,
    page,
    t,
    selectedFilterData,
    filterModalData,
    dispatch,
  }: IParamChangeLogData): Promise<void> {
    try {
      dispatch(setError(false))
      const tData: ITable = {
        ...tableData,
        colDefs: getColumnData(t),
        rowData: [],
        loading: true,
      }
      dispatch(setChangeLogTableData(tData))

      const data: { rowData: Array<ITableRow>; paginator: IPaginator } = await this.getChangeLogData({
        accessToken,
        projectQuestionnaireId,
        app,
        tenantId,
        page,
        orderBy,
        selectedFilterData,
        filterModalData,
      })
      dispatch(
        setChangeLogTableData({
          ...tData,
          rowData: data.rowData,
          paginator: {
            ...DEFAULT_PAGINATOR_DATA,
            page: page + 1,
          },
          orderBy,
          loading: false,
          orderByColumn: 'date',
          hasMore: _.gt(data.paginator.totalItems, data.rowData.length),
        }),
      )
    } catch {
      dispatch(setError(true))
    }
  }

  /**
   * Get change log data
   * @param {interface} IParamChangeLogData
   * @returns {Promise<void>}
   */
  public static async handleFilterChange({
    accessToken,
    app,
    orderBy,
    projectQuestionnaireId,
    tenantId,
    tableData,
    page,
    selectedFilterData,
    filterModalData,
    dispatch,
  }: IParamChangeLogData): Promise<void> {
    try {
      dispatch(setError(false))
      dispatch(
        setChangeLogTableData({
          ...tableData,
          loading: true,
          rowData: [],
        }),
      )

      const data: { rowData: Array<ITableRow>; paginator: IPaginator } = await this.getChangeLogData({
        accessToken,
        projectQuestionnaireId,
        app,
        tenantId,
        page,
        orderBy,
        selectedFilterData,
        filterModalData,
      })

      dispatch(
        setChangeLogTableData({
          ...tableData,
          rowData: data.rowData,
          paginator: {
            ...DEFAULT_PAGINATOR_DATA,
            page,
          },
          orderBy,
          loading: false,
          orderByColumn: 'date',
          hasMore: _.gt(data.paginator.totalItems, data.rowData.length),
        }),
      )
    } catch {
      dispatch(setError(true))
    }
  }

  /**
   * Get change log data
   * @param {interface} IParamChangeLogData
   * @returns {Promise<void>}
   */
  public static async loadMoreData({
    accessToken,
    app,
    orderBy,
    projectQuestionnaireId,
    tenantId,
    tableData,
    selectedFilterData,
    filterModalData,
    dispatch,
  }: IParamChangeLogData): Promise<void> {
    try {
      dispatch(setError(false))
      dispatch(
        setChangeLogTableData({
          ...tableData,
          loading: true,
        }),
      )
      const page: number = tableData.paginator.page
      const data: { rowData: Array<ITableRow>; paginator: IPaginator } = await this.getChangeLogData({
        accessToken,
        projectQuestionnaireId,
        app,
        tenantId,
        page,
        orderBy,
        selectedFilterData,
        filterModalData,
      })

      const rowData: Array<ITableRow> = _.uniqBy(_.concat(tableData.rowData, data.rowData), 'id')

      dispatch(
        setChangeLogTableData({
          ...tableData,
          rowData: rowData,
          paginator: {
            ...tableData.paginator,
            page: page + 1,
          },
          orderBy,
          loading: false,
          hasMore: _.gt(data.paginator.totalItems, rowData.length),
        }),
      )
    } catch {
      dispatch(setError(true))
    }
  }
}
