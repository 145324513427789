import { Packer } from 'docx'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { WppIconExportFile, WppListItem, WppTypography } from 'buildingBlocks'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import { AppDispatch } from 'store'
import { showAlert } from 'store/reducers/alertSlice'
import DocHelper from 'utils/file/DocHelper'

interface IDocProps {
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  app: IApp
  projectQuestionnaireId: string
}

/**
 * Create word document file
 */
const Doc: React.FC<IDocProps> = ({ handleAppAction, app, projectQuestionnaireId }: IDocProps): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation()
  const generateDoc = () => {
    const doc = DocHelper.createDoc(app, projectQuestionnaireId, t)
    Packer.toBlob(doc)
      .then((blob: Blob) => {
        saveAs(blob, `${app.appName}.docx`)
      })
      .catch(() => {
        dispatch(
          showAlert({
            message: t('action.export.documentError'),
            type: TOAST_MESSAGE_TYPES.ERROR,
            open: true,
          }),
        )
      })
  }

  const handleDocExport = () => {
    handleAppAction(APP_ACTIONS.EXPORT, (message: TOAST_MESSAGE_TYPES) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR)) generateDoc()
    })
  }

  return (
    <WppListItem data-testid="btn-docx" onWppChangeListItem={handleDocExport}>
      <p slot="left">
        <WppIconExportFile />
      </p>
      <p slot="label">
        <WppTypography tag="span" type="s-body">
          <Trans>action.export.titleDocx</Trans>
        </WppTypography>
      </p>
    </WppListItem>
  )
}

export default Doc
