// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dyUPl{max-height:300px;overflow:auto;display:flex;flex-direction:column-reverse}.dyUPl .FJd30{display:flex;flex-direction:column-reverse;gap:12px;overflow:hidden !important}.dyUPl::-webkit-scrollbar{width:10px}.dyUPl::-webkit-scrollbar-track{background-color:#f1f1f1}.dyUPl::-webkit-scrollbar-thumb{background-color:var(--wpp-grey-color-400)}.cmIxk{z-index:9999}.cmIxk .iTQF0{display:flex;align-items:center;gap:12px;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldComment/formFieldCommentDetail/FormFieldCommentDetail.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CAEA,cACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,0BAAA,CAGF,0BACE,UAAA,CAGF,gCACE,wBAAA,CAGF,gCACE,0CAAA,CAIJ,OACE,YAAA,CACA,cACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,wBAAA","sourcesContent":[".rootContainer {\n  max-height: 300px;\n  overflow: auto;\n  display: flex;\n  flex-direction: column-reverse;\n\n  .infiniteScrollContainer {\n    display: flex;\n    flex-direction: column-reverse;\n    gap: 12px;\n    overflow: hidden !important;\n  }\n\n  &::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background-color: #f1f1f1;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--wpp-grey-color-400);\n  }\n}\n\n.modal {\n  z-index: 9999;\n  .modalAction {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    justify-content: flex-end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootContainer": `dyUPl`,
	"infiniteScrollContainer": `FJd30`,
	"modal": `cmIxk`,
	"modalAction": `iTQF0`
};
export default ___CSS_LOADER_EXPORT___;
