import React from 'react'

import Field from 'app/components/form/formBuilder/formField/Field'
import styles from 'app/components/form/formBuilder/formField/FormField.module.scss'
import { WppGrid } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import IForm from 'interfaces/form/IForm'

interface IFormFieldProps {
  /** form element */
  form: IForm
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build field
 * @param {object} props
 * @param {IForm} props.form
 * @param {Function} props.handleChange
 */
const FormField: React.FC<IFormFieldProps> = (props: IFormFieldProps): React.ReactElement => {
  return (
    <WppGrid item all={24} className={styles.fieldsContainer}>
      {props.form.fields.map((field: IField) => (
        <Field key={field.id} field={field} {...props} isChildField={false} />
      ))}
    </WppGrid>
  )
}

export default FormField
