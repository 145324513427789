import React from 'react'

import styles from 'app/components/form/formBuilder/formField/actions/Style.module.scss'
import { WppIconTrash } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IAddFormFieldProps {
  /** field element */
  field: IField
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Repeatable field element id */
  repeatableFieldId?: string
}

/**
 * Build remove field
 * @param {object} props Component props
 * @param {IField} props.field
 * @param {string} props.repeatableFieldId
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 */
const RemoveFormField: React.FC<IAddFormFieldProps> = ({
  field,
  handleChange,
  repeatableFieldId,
}: IAddFormFieldProps): React.ReactElement => {
  return (
    <WppIconTrash
      data-testid="remove-form-field"
      onClick={() => {
        if (repeatableFieldId) {
          handleChange({
            field,
            value: field.value,
            repeatableEventType: 'remove',
            repeatableFieldId,
          })
        }
      }}
      className={styles.btnRemove}
    />
  )
}

export default RemoveFormField
