import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { WppTag } from 'buildingBlocks'
import { REVIEW_RESPONSE_TYPES } from 'config/enums'

interface IReviewerStatusProps {
  status: REVIEW_RESPONSE_TYPES | null
}

/**
 * Reviewer status component
 * @param {object} props
 * @param {REVIEW_RESPONSE_TYPES | null} props.status
 */
const ReviewerStatus: React.FC<IReviewerStatusProps> = ({ status }: IReviewerStatusProps): React.ReactElement => {
  const { t } = useTranslation()

  if (_.isNull(status) || _.isEqual(status, REVIEW_RESPONSE_TYPES.PENDING)) {
    return <WppTag variant="neutral" label={t('review.reviewersList.responseTypes.pending')} />
  }

  if (_.isEqual(status, REVIEW_RESPONSE_TYPES.APPROVAL)) {
    return <WppTag variant="positive" label={t('review.reviewersList.responseTypes.approved')} />
  }

  return <WppTag variant="negative" label={t('review.reviewersList.responseTypes.rejected')} />
}

export default ReviewerStatus
