import classnames from 'classnames'
import _ from 'lodash'
import { Trans } from 'react-i18next'

import { WppTypography } from 'buildingBlocks'
import styles from 'buildingBlocks/mention/MentionEditor.module.scss'
import { COMMENT_TEXT_EDITOR_MAX_LENGTH } from 'config/constants'

interface InputActionProps {
  textCharCount: number
}

const InputAction = ({ textCharCount }: InputActionProps) => {
  let textColorStyle = _.gte(textCharCount, COMMENT_TEXT_EDITOR_MAX_LENGTH - 100)
    ? styles.characterLimitLabelWarning
    : ''

  if (_.gt(textCharCount, COMMENT_TEXT_EDITOR_MAX_LENGTH)) {
    textColorStyle = styles.characterLimitLabelError
  }

  return (
    <div className={styles.inputAction}>
      <div className={classnames(styles.characterLimit, textColorStyle)}>
        <WppTypography className={textColorStyle} tag="p" type="xs-body">
          <Trans>comment.text.characters</Trans>
        </WppTypography>
        <WppTypography className={textColorStyle} tag="p" type="xs-strong">
          {textCharCount}/{COMMENT_TEXT_EDITOR_MAX_LENGTH}
        </WppTypography>
      </div>
    </div>
  )
}

export default InputAction
