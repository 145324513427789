import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, { defaultSuggestionsFilter, MentionData } from '@draft-js-plugins/mention'
import { EntryComponentProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry'
import classNames from 'classnames'
import { EditorState, Modifier } from 'draft-js'
import _ from 'lodash'
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react'

import InputAction from 'buildingBlocks/mention/InputAction'
import styles from 'buildingBlocks/mention/MentionEditor.module.scss'
import UserAvatar from 'buildingBlocks/userAvatar'
import FieldCommentHelper from 'utils/formField/FieldCommentHelper'

function MentionEntry(props: EntryComponentProps): ReactElement {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props

  return (
    <div {...parentProps}>
      <div className={styles.mentionContainer}>
        <UserAvatar
          fullName={mention.name}
          avatarSize="s"
          avatarUrl={mention.avatar ?? ''}
          email={_.toString(mention.id)}
        />
      </div>
    </div>
  )
}

interface IMentionEditorProps {
  mentionOptions: MentionData[]
  editorState: EditorState
  setEditorState: (editorState: EditorState) => void
  readOnly?: boolean
  isNewComment?: boolean
  onAddMention?: (mention: MentionData) => void
}

const MentionEditor = ({
  setEditorState,
  editorState,
  readOnly,
  mentionOptions,
  isNewComment,
  onAddMention,
}: IMentionEditorProps): ReactElement => {
  const ref = useRef<Editor>(null)
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState(mentionOptions)

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: styles,
      mentionPrefix: '@',
      supportWhitespace: true,
    })
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open)
  }, [])

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentionOptions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        className={classNames(styles.editor, { [styles.readOnlyEditor]: readOnly, [styles.newRecord]: isNewComment })}
        onClick={() => {
          ref.current!.focus()
        }}
      >
        <Editor
          readOnly={readOnly}
          role="textbox"
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          ref={ref}
          handlePastedText={(text: string) => {
            const newContent = Modifier.insertText(editorState.getCurrentContent(), editorState.getSelection(), text)
            setEditorState(EditorState.push(editorState, newContent, 'insert-characters'))
            return 'handled'
          }}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          entryComponent={MentionEntry}
          onAddMention={onAddMention}
        />
      </div>
      {!readOnly && <InputAction textCharCount={FieldCommentHelper.getCharCount(editorState)} />}
    </div>
  )
}

export default MentionEditor
