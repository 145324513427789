import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/toast/Toast.module.scss'
import { WppToastContainer } from 'buildingBlocks'
import useToast from 'hooks/useToast'
import { RootState } from 'store'
import IAlertState from 'store/interfaces/IAlertState'
import { hideAlert } from 'store/reducers/alertSlice'

/**
 * Toast Component
 */
const Toast: React.FC = (): React.ReactElement => {
  const childRef = useRef(null)
  const dispatch = useDispatch()
  const { showToast } = useToast()
  const { open, message, type, duration, header } = useSelector<RootState, IAlertState>(
    (state: RootState) => state.alertState,
  )

  useEffect(() => {
    let timer: number = -1
    if (open) {
      showToast({
        header: header ?? '',
        message,
        type,
        duration,
      })

      timer = window.setTimeout(() => {
        dispatch(hideAlert())
        clearTimeout(timer)
      }, duration)
    }

    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, message, type, header, duration])

  return <WppToastContainer className={styles.toastContainer} id="wpp-toast" maxToastsToDisplay={5} ref={childRef} />
}

export default Toast
