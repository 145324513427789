import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldRadioCheckLabel/FormFieldRadioCheckLabel.module.scss'
import FormFieldTooltip from 'app/components/form/formField/formFieldTooltip'
import { WppTypography } from 'buildingBlocks'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldRadioCheckLabelProps {
  /** Field ID */
  id: string
  /** Label title */
  text: string
  /** Help Modal */
  helpModal?: IHelpModal
  /** If element is disabled */
  disabled: boolean
  /** handle input change event */
  handleChange?: () => void
  /** Tooltip */
  tooltip?: string
  /** Field description */
  description?: string
}

/**
 * Create label for the checkbox and radio button options
 * @param {object} props
 * @param {string} props.id
 * @param {string} props.text
 * @param {IHelpModal} props.helpModal
 * @param {boolean} props.disabled
 * @param {function} props.handleChange
 * @param {string} props.tooltip
 * @param {string} props.description
 */
const FormFieldRadioCheckLabel: React.FC<IFormFieldRadioCheckLabelProps> = (
  props: IFormFieldRadioCheckLabelProps,
): React.ReactElement => {
  const { id, text, disabled, helpModal, tooltip, description, handleChange } = props

  const desc = description ?? ''
  const tooltipText = tooltip ?? ''

  return (
    <>
      {!_.isEmpty(text) && (
        <div className={styles.container}>
          <WppTypography className={styles.label} aria-disabled={disabled} id={`label-${id}`} type="s-body">
            <div className={styles.labelContainer}>
              <span
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
                id={`btn-label-${id}`}
                data-testid={`btn-label-${id}`}
                className={styles.btnLabel}
                onClick={event => {
                  if (_.isEqual(event.target, event.currentTarget) && !disabled && handleChange) {
                    handleChange()
                  }
                }}
                aria-hidden={true}
              />
              <div className={styles.tooltip}>
                <FormFieldTooltip helpModal={helpModal} tooltip={tooltipText} />
              </div>
            </div>
          </WppTypography>
        </div>
      )}
      {!_.isEmpty(desc) && (
        <WppTypography className={styles.description} type="s-body">
          <span
            dangerouslySetInnerHTML={{
              __html: desc,
            }}
          />
        </WppTypography>
      )}
    </>
  )
}

export default FormFieldRadioCheckLabel
