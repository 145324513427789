import { MouseEventHandler } from 'react'
import { createPortal } from 'react-dom'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from 'app/components/review/approve/approveModal/ApproveModal.module.scss'
import { WppButton, WppModal } from 'buildingBlocks'
import { AppDispatch, RootState } from 'store'
import IReviewState from 'store/interfaces/IReviewState'
import { setApproveModal } from 'store/reducers/reviewSlice'

interface IApproveModalProps {
  /** Handle approve action*/
  onApprove: MouseEventHandler<HTMLWppButtonElement>
}

/**
 * Approve Modal component
 * @param {object} props
 * @param {MouseEventHandler<HTMLWppButtonElement>} props.onApprove
 */
const ApproveModal: React.FC<IApproveModalProps> = ({ onApprove }: IApproveModalProps): React.ReactElement => {
  const reviewState = useSelector<RootState, IReviewState>((state: RootState) => state.reviewState)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <>
      {createPortal(
        <WppModal open={reviewState.isApproveModalOpen}>
          <h3 slot="header">
            <Trans>review.approveModal.title</Trans>
          </h3>
          <p slot="body">
            <Trans>review.approveModal.body</Trans>
          </p>
          <div slot="actions" className={styles.actions}>
            <WppButton
              data-testid="btn-cancel"
              size="s"
              variant="secondary"
              onClick={() => dispatch(setApproveModal(false))}
            >
              <Trans>app.button.cancel</Trans>
            </WppButton>
            <WppButton data-testid="btn-approve" size="s" onClick={onApprove}>
              <Trans>app.button.approve</Trans>
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </>
  )
}

export default ApproveModal
