// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lQZaa{padding:5px 8px}.HuP5c{margin-top:12px;max-height:210px;overflow:auto}.Tjvte::part(actions-wrapper){display:flex;justify-content:flex-end;border-top:1px solid var(--wpp-grey-color-300)}.Tjvte::part(header-wrapper){border-bottom:1px solid var(--wpp-grey-color-300)}.Tjvte .sYrYN{padding:16px 0px}.bGyCo{position:absolute;top:4px;left:0px}.vMmkU{top:4px;left:40px}.uuH01{top:4px;left:15px}`, "",{"version":3,"sources":["webpack://./src/app/components/review/selectedReviewer/SelectedReviewer.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGF,OACE,eAAA,CACA,gBAAA,CACA,aAAA,CAIA,8BACE,YAAA,CACA,wBAAA,CACA,8CAAA,CAGF,6BACE,iDAAA,CAGF,cACE,gBAAA,CAIJ,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CAEA,OACE,OAAA,CACA,SAAA,CAGF,OACE,OAAA,CACA,SAAA","sourcesContent":[".userAvatar {\n  padding: 5px 8px;\n}\n\n.unauthorizedReviewers {\n  margin-top: 12px;\n  max-height: 210px;\n  overflow: auto;\n}\n\n.modal {\n  &::part(actions-wrapper) {\n    display: flex;\n    justify-content: flex-end;\n    border-top: 1px solid var(--wpp-grey-color-300);\n  }\n\n  &::part(header-wrapper) {\n    border-bottom: 1px solid var(--wpp-grey-color-300);\n  }\n\n  .body {\n    padding: 16px 0px;\n  }\n}\n\n.btnSetReviewerAction {\n  position: absolute;\n  top: 4px;\n  left: 0px;\n\n  &__comments {\n    top: 4px;\n    left: 40px;\n  }\n\n  &__commentIcon {\n    top: 4px;\n    left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userAvatar": `lQZaa`,
	"unauthorizedReviewers": `HuP5c`,
	"modal": `Tjvte`,
	"body": `sYrYN`,
	"btnSetReviewerAction": `bGyCo`,
	"btnSetReviewerAction__comments": `vMmkU`,
	"btnSetReviewerAction__commentIcon": `uuH01`
};
export default ___CSS_LOADER_EXPORT___;
