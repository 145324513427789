import * as _ from 'lodash'
import React, { useCallback, useState } from 'react'

import styles from 'app/components/form/formField/formFieldTooltip/FormFieldTooltip.module.scss'
import Help from 'app/components/help'
import { WppIconInfo, WppTooltip, WppTypography } from 'buildingBlocks'
import IHelpModal from 'interfaces/field/IHelpModal'

interface IFormFieldTooltipProps {
  /** IHelpModal interface */
  helpModal?: IHelpModal
  tooltip: string
}

/**
 * Create help content with side panel
 * @param {object} props
 * @param {IHelpModal} props.helpModal
 * @param {string} props.tooltip
 */
const FormFieldTooltip: React.FC<IFormFieldTooltipProps> = ({
  helpModal,
  tooltip,
}: IFormFieldTooltipProps): React.ReactElement => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalAction = useCallback((): void => {
    if (!_.isNull(helpModal)) {
      setModalOpen(!modalOpen)
    }
  }, [modalOpen, helpModal])

  if (_.isEmpty(tooltip) && !helpModal) {
    return <></>
  }

  let infoIcon = <WppIconInfo className={styles.infoIcon} />

  if (tooltip && !_.isEmpty(tooltip)) {
    infoIcon = (
      <WppTooltip
        component={infoIcon}
        allowHTML
        tooltipHTMLContent={
          <WppTypography type="s-body" tag="p" className={styles.tooltipContainer}>
            <span
              dangerouslySetInnerHTML={{
                __html: tooltip,
              }}
            />
          </WppTypography>
        }
      />
    )
  }

  return (
    <>
      {helpModal?.title && (
        <Help
          title={helpModal?.title}
          content={helpModal?.content}
          isModalOpen={modalOpen}
          handleModalAction={handleModalAction}
        />
      )}
      <button
        onClick={event => {
          event.stopPropagation()
          handleModalAction()
        }}
        className={styles.btnIcon}
      >
        {infoIcon}
      </button>
    </>
  )
}

export default FormFieldTooltip
