import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IApp from 'interfaces/app/IApp'
import IAppStatus from 'interfaces/app/IAppStatus'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

const defaultQuestionnaireState: IQuestionnaireState = {
  loading: false,
  app: null,
  appStatus: {
    isAppTouched: false,
    openModal: false,
  },
  error: false,
  activeStep: 1,
  enableCommentMode: false,
}

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: defaultQuestionnaireState,
  reducers: {
    setLoading: (state: IQuestionnaireState, action: PayloadAction<boolean>): IQuestionnaireState => {
      return {
        ...state,
        loading: action.payload,
      }
    },
    setAppStatus: (state: IQuestionnaireState, action: PayloadAction<IAppStatus>): IQuestionnaireState => {
      return {
        ...state,
        appStatus: {
          ...state.appStatus,
          ...action.payload,
        },
      }
    },
    setStep: (state: IQuestionnaireState, action: PayloadAction<number>): IQuestionnaireState => {
      return {
        ...state,
        activeStep: action.payload,
      }
    },
    setApp: (state: IQuestionnaireState, action: PayloadAction<IApp | null>): IQuestionnaireState => {
      return {
        ...state,
        app: action.payload,
      }
    },
    setError: (state: IQuestionnaireState): IQuestionnaireState => {
      return {
        ...state,
        error: true,
      }
    },
    setState: (state: IQuestionnaireState, action: PayloadAction<IQuestionnaireState>): IQuestionnaireState => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLoading, setApp, setAppStatus, setError, setStep, setState } = questionnaireSlice.actions

export default questionnaireSlice.reducer
