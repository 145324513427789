import { StyleSheet } from '@react-pdf/renderer'

import { PDF_FONT_FAMILY_NAME } from 'config/constants'

// Create styles
const styles = StyleSheet.create({
  list: {
    display: 'flex',
    gap: 4,
  },
  listItem: {
    fontSize: 12,
    marginHorizontal: 8,
    fontFamily: PDF_FONT_FAMILY_NAME,
    fontWeight: 300,
  },
  fieldContainer: {
    display: 'flex',
    gap: 4,
  },
  text: {
    fontSize: 12,
    fontFamily: PDF_FONT_FAMILY_NAME,
    fontWeight: 300,
  },
  fieldHeader: {
    fontSize: 12,
    fontFamily: PDF_FONT_FAMILY_NAME,
  },
})

export default styles
