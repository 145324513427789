import { WppCounterCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import _ from 'lodash'
import React from 'react'

import formStyles from 'app/components/form/Form.module.scss'
import styles from 'app/components/form/formField/formFieldCounter/FormFieldCounter.module.scss'
import { WppCounter } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { CounterChangeEventDetail } from 'utils/types'

interface IFormFieldCounterProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create counter field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldCounter: React.FC<IFormFieldCounterProps> = ({
  field,
  handleChange,
}: IFormFieldCounterProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const Value: number = !_.isNumber(value) ? 1 : value

  return (
    <div className={styles.container}>
      <WppCounter
        value={Value}
        onWppChange={(event: WppCounterCustomEvent<CounterChangeEventDetail>) =>
          handleChange({
            field,
            value: event.detail.value,
          })
        }
        id={`counter-${field.id}`}
        disabled={disabled}
        format={{
          searchValue: /(.)(?=(\d{3})+$)/g,
          replaceValue: '$1 ',
        }}
        required={field.isRequired}
        messageType={messageType}
        maxMessageLength={400}
        message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
        className={_.isEqual(messageType, 'error') ? '' : formStyles.customStyleDefaultMessage}
      />
    </div>
  )
}

export default FormFieldCounter
