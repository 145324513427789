import { AxiosResponse } from 'axios'
import _ from 'lodash'

import { getUploadFileAPIPath, getUploadSignedAPIPath } from 'config/apiPaths'
import IFile from 'interfaces/file/IFile'
import IFileSignedURLResponse from 'interfaces/file/IFileSignedURLResponse'
import IUploadedFIle from 'interfaces/file/IUploadedFIle'
import AxiosService from 'lib/AxiosService'

export default class FileHelper {
  private axiosService: AxiosService
  constructor(accessToken: string) {
    this.axiosService = new AxiosService(accessToken)
  }
  /**
   * Create Upload Signed URL
   * @param {IPermission[]} permissions
   * @returns {string}
   */
  async getUploadSignedURL(file: File, tenantId: string, projectId: string): Promise<IFileSignedURLResponse[]> {
    const Response: AxiosResponse<IFileSignedURLResponse[]> = await this.axiosService.post(
      getUploadSignedAPIPath(),
      {
        projectId,
        names: [file.name],
        contentType: file.type,
      },
      tenantId,
    )
    return Response.data
  }

  /**
   * Upload file to a project
   * @param {File} file
   * @param {string} tenantId
   * @param {string} projectId
   * @returns {Promise<IFile>}
   */
  async uploadFile(file: File, tenantId: string, tenantUrl: string, projectId: string): Promise<IFile> {
    const UpdatedFile: File = new File([file], file.name, {
      type: file.type,
    })

    const SignedURLs: IFileSignedURLResponse[] = await this.getUploadSignedURL(UpdatedFile, tenantId, projectId)

    const UploadFileSignedURL: IFileSignedURLResponse = SignedURLs[0]

    await this.axiosService.put(UploadFileSignedURL.signedUrl, UpdatedFile, tenantId, {
      headers: {
        'Content-Type': UpdatedFile.type,
      },
    })

    const UploadedFile: AxiosResponse<IUploadedFIle[]> = await this.axiosService.post(
      getUploadFileAPIPath(projectId),
      [
        {
          key: UploadFileSignedURL.key,
          name: UploadFileSignedURL.name,
          size: UpdatedFile.size,
        },
      ],
      tenantId,
    )

    const FileID: string = UploadedFile.data[0].id

    return {
      file: {
        name: file.name,
        size: file.size,
        type: file.type,
        url: `${tenantUrl}orchestration/project/${projectId}/files?view=filePreview&id=${FileID}`,
      },
      id: FileID,
    }
  }

  /**
   * Get Input Files
   * @param {CustomEvent} event
   * @param {IFile[]} fileValue
   * @returns {IFile[]}
   */
  getInputFiles(event: CustomEvent, fileValue: IFile[]): IFile[] {
    // Valid files
    const ValidFiles = event.detail.value.map((file: File) => ({
      file,
      id: null,
    }))

    // Error files due to large size, incorrect file type
    const ErrorFiles = event.detail.errorFiles.map((file: File) => ({
      file,
      id: null,
    }))

    const Files = _.concat(ValidFiles, ErrorFiles)

    if (_.isEmpty(Files)) {
      return []
    }

    let fileValues: IFile[] = []

    // Update Removed File
    fileValues = fileValue.filter(({ file }: IFile) => {
      const IsFilePresent = Files.findIndex((inputFile: IFile) => _.isEqual(inputFile.file.name, file.name))
      return !_.isEqual(IsFilePresent, -1)
    })

    const UpdatedFiles = Files.filter(({ file }: IFile) => {
      const IsValidInput = fileValues.find((fileInput: IFile) => _.isEqual(file.name, fileInput.file.name))
      return !IsValidInput
    })
    return _.concat(fileValues, UpdatedFiles)
  }
}
