import { useOs } from '@wppopen/react'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import Doc from 'app/components/action/export/doc'
import PdfViewer from 'app/components/action/export/pdfViewer'
import { WppActionButton, WppIconExportFile, WppMenuContext } from 'buildingBlocks'
import { TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import SharedHelper from 'utils/SharedHelper'

interface IExportProps {
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  app: IApp
  projectQuestionnaireId: string
}

/**
 * Export component (PDF and DOCX)
 * @param {object} props
 * @param {ICategory} props.category
 * @param {string} props.projectQuestionnaireId
 */
const Export: React.FC<IExportProps> = ({
  handleAppAction,
  app,
  projectQuestionnaireId,
}: IExportProps): React.ReactElement => {
  const { osContext } = useOs()
  const [loading, setLoading] = useState<boolean>(false)
  const [base64Logo, setBase64Logo] = useState<string>('')
  const logoOriginal = osContext.tenant.logoOriginal?.url ?? ''

  const setPDFLogo = useCallback(() => {
    if (logoOriginal) {
      setLoading(true)
      SharedHelper.getBase64Image(logoOriginal, (data: string) => {
        setBase64Logo(data)
        setLoading(false)
      })
    }
  }, [logoOriginal])

  useEffect(() => {
    setPDFLogo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoOriginal])

  return (
    <WppMenuContext>
      <WppActionButton loading={loading} slot="trigger-element">
        <WppIconExportFile slot="icon-start" size="s" />
        <Trans>action.export.title</Trans>
      </WppActionButton>
      <div>
        <PdfViewer
          app={app}
          base64Logo={base64Logo}
          handleAppAction={handleAppAction}
          projectQuestionnaireId={_.toString(projectQuestionnaireId)}
        />
        <Doc app={app} handleAppAction={handleAppAction} projectQuestionnaireId={_.toString(projectQuestionnaireId)} />
      </div>
    </WppMenuContext>
  )
}

export default Export
