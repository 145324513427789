// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x_xrT{height:100%;overflow:auto;padding-bottom:8px}.x_xrT .EtSzK{width:100%;border-collapse:separate;max-height:70vh;overflow:auto}.cqnSX{padding:8px 0px}`, "",{"version":3,"sources":["webpack://./src/buildingBlocks/table/Table.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,aAAA,CACA,kBAAA,CACA,cACE,UAAA,CACA,wBAAA,CACA,eAAA,CACA,aAAA,CAGJ,OACE,eAAA","sourcesContent":[".tableContainer {\n  height: 100%;\n  overflow: auto;\n  padding-bottom: 8px;\n  .table {\n    width: 100%;\n    border-collapse: separate;\n    max-height: 70vh;\n    overflow: auto;\n  }\n}\n.tablePagination {\n  padding: 8px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `x_xrT`,
	"table": `EtSzK`,
	"tablePagination": `cqnSX`
};
export default ___CSS_LOADER_EXPORT___;
