import _ from 'lodash'
import React from 'react'

import FormFieldSelect from 'app/components/form/formField/formFieldSelect'
import FormFieldTextInput from 'app/components/form/formField/formFieldTextInput'
import { WppGrid } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IFormFieldCurrencyProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create text input field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldCurrency: React.FC<IFormFieldCurrencyProps> = ({
  field,
  handleChange,
}: IFormFieldCurrencyProps): React.ReactElement => {
  const { currencyType, currencyAmount } = field.value
  const isValidCurrencyValue =
    field.isRequired && field.touched ? !_.isEmpty(_.toString(field.value.currencyAmount).trim()) : true
  const isValidCurrencyType = field.isRequired && field.touched ? !_.isNull(field.value.currencyType) : true

  return (
    <WppGrid fullWidth container>
      <WppGrid item xl={6} xxl={6} sm={6} md={6} all={6}>
        <FormFieldTextInput
          field={{
            ...field,
            value: field.value.currencyAmount,
            messageType: isValidCurrencyValue ? undefined : 'error',
            isValid: isValidCurrencyValue,
            errorMessage: !isValidCurrencyValue ? field.errorMessage : '',
          }}
          id={`currency-textinput-${field.id}`}
          handleChange={(event: IFieldChangeEvent) => {
            handleChange({
              field,
              value: {
                currencyType,
                currencyAmount: event.value,
              },
            })
          }}
          inputType="text"
        />
      </WppGrid>
      <WppGrid item xl={18} xxl={18} sm={18} md={18} all={18}>
        <FormFieldSelect
          field={{
            ...field,
            value: field.value.currencyType,
            isValid: isValidCurrencyType,
            messageType: isValidCurrencyType ? undefined : 'error',
            errorMessage: !isValidCurrencyType ? field.errorMessage : '',
            fieldConfig: {
              ...field.fieldConfig,
              placeholder: 'Select currency',
            },
          }}
          id={`currency-select-${field.id}`}
          handleChange={(event: IFieldChangeEvent) => {
            handleChange({
              field,
              value: {
                currencyType: event.value,
                currencyAmount,
              },
            })
          }}
        />
      </WppGrid>
    </WppGrid>
  )
}

export default FormFieldCurrency
