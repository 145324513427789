import * as _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import styles from 'app/components/form/formBuilder/formField/actions/Style.module.scss'
import { WppActionButton, WppIconAdd } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IAddFormFieldProps {
  /** field element */
  field: IField
  /** Handle input change event. It has InputFieldChangeEvent object which have IField, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Build add field
 * @param {object} props Component props
 * @param {IForm} props.field Form object
 * @param {(event: IFieldChangeEvent) => void} props.handleChange handle input field change event
 */
const AddFormField: React.FC<IAddFormFieldProps> = ({
  field,
  handleChange,
}: IAddFormFieldProps): React.ReactElement => {
  return (
    <WppActionButton
      className={styles.btnAdd}
      data-testid="add-form-field"
      disabled={_.isEqual(field.maxRepeatableAmount, field.repeatableFields.length)}
      onClick={() => {
        handleChange({
          field,
          value: field.value,
          repeatableEventType: 'add',
        })
      }}
    >
      <WppIconAdd slot="icon-start" />
      <Trans>app.button.add</Trans>
    </WppActionButton>
  )
}

export default AddFormField
