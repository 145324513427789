// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TOUgu{display:flex;align-items:center;justify-content:space-between}.TOUgu .dCTDK{display:flex;align-items:center;gap:12px}.xUM4L{display:flex;flex-direction:column;gap:24px}.xUM4L .p5bFE{display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./src/pages/changeLog/filter/Filter.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,QAAA,CAIJ,OACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,cACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .btnActionContainer {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n  }\n}\n\n.sideModalBody {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .fieldContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `TOUgu`,
	"btnActionContainer": `dCTDK`,
	"sideModalBody": `xUM4L`,
	"fieldContainer": `p5bFE`
};
export default ___CSS_LOADER_EXPORT___;
