import { StyleSheet } from '@react-pdf/renderer'

import { PDF_FONT_FAMILY_NAME } from 'config/constants'

// Create styles
const styles = StyleSheet.create({
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: PDF_FONT_FAMILY_NAME,
  },
  tenantLogo: { maxWidth: 100, marginBottom: 20 },
  pageHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    flexDirection: 'row',
    marginBottom: 8,
    justifyContent: 'center',
    alignContent: 'center',
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  disabled: {
    opacity: 0.5,
  },
})
export default styles
