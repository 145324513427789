// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.obpb1,.NDORg{padding-top:0}.NDORg{display:flex;justify-content:space-between;gap:8px}.IrI6d{position:relative}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formBuilder/formFieldBuilder/FormFieldBuilder.module.scss"],"names":[],"mappings":"AAAA,cACE,aAAA,CAGF,OAEE,YAAA,CACA,6BAAA,CACA,OAAA,CAGF,OACE,iBAAA","sourcesContent":[".itemContainer {\n  padding-top: 0;\n}\n\n.labelContainer {\n  @extend .itemContainer;\n  display: flex;\n  justify-content: space-between;\n  gap: 8px;\n}\n\n.commentApproverContainer {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemContainer": `obpb1`,
	"labelContainer": `NDORg`,
	"commentApproverContainer": `IrI6d`
};
export default ___CSS_LOADER_EXPORT___;
