// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dGIJk{margin-bottom:32px;display:flex;justify-content:flex-end;width:100%;gap:12px;margin-top:12px}`, "",{"version":3,"sources":["webpack://./src/app/components/stepper/Stepper.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,YAAA,CACA,wBAAA,CACA,UAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".stepContainer{\n  margin-bottom: 32px;\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  gap: 12px;\n  margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepContainer": `dGIJk`
};
export default ___CSS_LOADER_EXPORT___;
