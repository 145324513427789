// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CyZ03{display:flex;gap:12px;justify-content:flex-end}._UrgA{display:flex;gap:24px;flex-direction:column}._UrgA .IZyKZ{display:flex;gap:8px;flex-direction:column}._UrgA .IZyKZ .cEfqa::part(wrapper){width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/review/sendToReview/SendToReview.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,QAAA,CACA,wBAAA,CAGF,OACE,YAAA,CACA,QAAA,CACA,qBAAA,CAEA,cACE,YAAA,CACA,OAAA,CACA,qBAAA,CAEA,oCACE,UAAA","sourcesContent":[".actions {\n  display: flex;\n  gap: 12px;\n  justify-content: flex-end;\n}\n\n.sideModalBody {\n  display: flex;\n  gap: 24px;\n  flex-direction: column;\n\n  .reviewTypeContainer {\n    display: flex;\n    gap: 8px;\n    flex-direction: column;\n\n    .reviewTypes::part(wrapper) {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": `CyZ03`,
	"sideModalBody": `_UrgA`,
	"reviewTypeContainer": `IZyKZ`,
	"reviewTypes": `cEfqa`
};
export default ___CSS_LOADER_EXPORT___;
