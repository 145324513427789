// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wVguW{margin-left:.2rem;cursor:pointer}.co1oV{color:var(--wpp-white-color)}.DUb2s{height:20px}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldTooltip/FormFieldTooltip.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,cAAA,CAGF,OACE,4BAAA,CAGF,OACE,WAAA","sourcesContent":[".infoIcon {\n  margin-left: 0.2rem;\n  cursor: pointer;\n}\n\n.tooltipContainer {\n  color: var(--wpp-white-color);\n}\n\n.btnIcon {\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoIcon": `wVguW`,
	"tooltipContainer": `co1oV`,
	"btnIcon": `DUb2s`
};
export default ___CSS_LOADER_EXPORT___;
