import { StyleSheet } from '@react-pdf/renderer'

import { PDF_FONT_FAMILY_NAME } from 'config/constants'

// Create styles
const styles = StyleSheet.create({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  formHeader: {
    fontSize: 13,
    fontFamily: PDF_FONT_FAMILY_NAME,
  },
  formDescription: {
    fontSize: 12,
    fontFamily: PDF_FONT_FAMILY_NAME,
    fontWeight: 300,
  },
})

export default styles
