import _ from 'lodash'
import moment from 'moment'

import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IChangeLogFilter from 'interfaces/changeLog/IChangeLogFilter'
import IChangeLogFilterModal from 'interfaces/changeLog/IChangeLogFilterModal'
import IField from 'interfaces/field/IField'

export default class FilterHelper {
  /**
   * Get Categories
   * @param {IApp} app
   * @returns {Array<ICategory>}
   */
  public static getCategories(app: IApp): Array<ICategory> {
    let categories: Array<ICategory> = []
    app.categories.forEach((category: ICategory) => {
      categories.push(category)
    })
    return _.orderBy(categories, ['order'], 'asc')
  }

  /**
   * Get form field
   * @param {IField} field
   * @returns {Array<IField>}
   */
  public static getFormFields(field: IField): Array<IField> {
    let fields: Array<IField> = []
    fields.push({
      ...field,
      fieldConfig: {
        ...field.fieldConfig,
        text: _.isEmpty(_.trim(field.fieldConfig.text)) ? 'EMPTY LABEL' : field.fieldConfig.text,
      },
    })
    field.children.forEach((childField: IField) => {
      fields = [...fields, ...this.getFormFields(childField)]
    })
    return fields
  }

  /**
   * Get Fields by categories
   * @param {Array<ICategory>} categories
   * @returns {Array<IField>}
   */
  public static getFieldsByCategories(categories: Array<ICategory>): Array<IField> {
    let fields: Array<IField> = []

    for (let category of categories) {
      for (let form of category.forms) {
        for (let field of form.fields) {
          fields = [...fields, ...this.getFormFields(field)]
        }
      }
    }
    return _.orderBy(fields, ['fieldConfig.text'], 'asc')
  }

  /**
   * Get default date range for filter
   * @returns {Array<string>}
   */
  public static getFilterDefaultDateRange(): Array<string> {
    return [moment().subtract(30, 'days').format('MM/DD/YYYY'), moment().format('MM/DD/YYYY')]
  }

  /**
   * Get Filter count
   * @param {Array<IChangeLogFilterModal>} sideModalData
   * @param {Array<IChangeLogFilter>} filterSelectedData
   * @returns {number}
   */
  public static getFilterCount(sideModalData: IChangeLogFilterModal, filterSelectedData: IChangeLogFilter): number {
    let count: number = 0
    const { categories, formFields } = sideModalData
    const { selectedCategories, selectedFormFields, selectedUserField } = filterSelectedData.sideModal
    if (!_.isEqual(categories, selectedCategories)) count += 1
    if (!_.isEqual(formFields, selectedFormFields)) count += 1

    count = _.isEmpty(selectedUserField.value) ? count : count + 1

    return count
  }
}
