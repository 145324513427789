import { StyleSheet } from '@react-pdf/renderer'

// Create styles
const styles = StyleSheet.create({
  multiValChildren: {
    marginHorizontal: 8,
    gap: 8,
    marginLeft: 16,
  },
  singleValChildren: {
    marginHorizontal: 8,
    gap: 8,
  },
})

export default styles
