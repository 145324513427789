import { AvatarSize } from '@platform-ui-kit/components-library'
import { WppAvatar, WppTypography } from '@platform-ui-kit/components-library-react'
import classNames from 'classnames'
import React from 'react'

import styles from 'buildingBlocks/userAvatar/UserAvatar.module.scss'

interface IUserAvatarProps {
  fullName: string
  email: string
  avatarUrl: string | null
  avatarSize: AvatarSize
  className?: string
}

/**
 * User avatar
 * @param {object} props
 * @param {string} props.email
 * @param {string | null} props.avatarUrl
 * @param {AvatarSize} props.avatarSize
 * @param {string} props.className
 * @param {string} props.fullName
 */
const UserAvatar: React.FC<IUserAvatarProps> = ({
  email,
  avatarUrl,
  avatarSize,
  className,
  fullName,
}: IUserAvatarProps): React.ReactElement => {
  return (
    <span className={classNames(styles.container, className)}>
      <WppAvatar className={styles.userAvatar} size={avatarSize} name={fullName} src={avatarUrl ?? ''} />
      <span className={styles.userInfo}>
        <WppTypography tag="span" type="s-body">
          {fullName}
        </WppTypography>
        <WppTypography tag="span" type="xs-body">
          {email}
        </WppTypography>
      </span>
    </span>
  )
}

export default UserAvatar
