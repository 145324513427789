import { Page, Text, View, Document, Font, Image, pdf } from '@react-pdf/renderer'
import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'

import Category from 'app/components/action/export/pdfViewer/category'
import styles from 'app/components/action/export/pdfViewer/styles'
import { WppIconFilePdf, WppListItem, WppTypography } from 'buildingBlocks'
import { PDF_FONT_FAMILY_NAME, PDF_FONTS_SRC } from 'config/constants'
import { APP_ACTIONS, TOAST_MESSAGE_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'

interface IPdfViewerProps {
  app: IApp
  projectQuestionnaireId: string
  handleAppAction: (
    appAction: string,
    callback: (message: TOAST_MESSAGE_TYPES) => void,
    displayMessage?: boolean,
  ) => void
  base64Logo: string
}

Font.register({
  family: PDF_FONT_FAMILY_NAME,
  fonts: PDF_FONTS_SRC,
})

/**
 * PDF Viewer component
 * @param {object} props
 * @param {IApp} props.app
 * @param {string} props.projectQuestionnaireId
 * @param {Function} props.handleAppAction
 * @param {string} props.base64Logo
 */
const PdfViewer: React.FC<IPdfViewerProps> = ({
  app,
  handleAppAction,
  projectQuestionnaireId,
  base64Logo,
}: IPdfViewerProps): React.ReactElement => {
  const onDownload = async () => {
    const blob = await pdf(
      <Document title={app.appName}>
        <Page size="A4" style={styles.page}>
          {!_.isEmpty(base64Logo) && (
            <Image src={`data:image/png;base64,${base64Logo}`} style={styles.tenantLogo} fixed />
          )}
          <Text style={styles.header}>{app.appName}</Text>
          <View style={styles.categoryContainer}>
            {app.categories.map((category: ICategory) => (
              <Category
                key={category.id}
                category={category}
                app={app}
                projectQuestionnaireId={projectQuestionnaireId}
              />
            ))}
          </View>
        </Page>
      </Document>,
    ).toBlob()
    const pdfUrl = URL.createObjectURL(blob)
    window.open(pdfUrl, '_blank')
  }

  const handlePdfViewer = () => {
    handleAppAction(APP_ACTIONS.EXPORT, (message: TOAST_MESSAGE_TYPES) => {
      if (!_.isEqual(message, TOAST_MESSAGE_TYPES.ERROR)) onDownload()
    })
  }

  return (
    <WppListItem data-testid="btn-pdf" id="btn-pdf" onWppChangeListItem={handlePdfViewer}>
      <p slot="left">
        <WppIconFilePdf />
      </p>
      <p slot="label">
        <WppTypography tag="span" type="s-body">
          <Trans>action.export.titlePdfViewer</Trans>
        </WppTypography>
      </p>
    </WppListItem>
  )
}

export default PdfViewer
