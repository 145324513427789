import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { WppBanner } from 'buildingBlocks'
import { QUESTIONNAIRE_STATUS } from 'config/enums'
import { RootState } from 'store'
import IProjectBriefState from 'store/interfaces/IProjectBriefState'
import IQuestionnaireState from 'store/interfaces/IQuestionnaireState'

/**
 * RejectBanner
 */
const RejectBanner: React.FC = (): React.ReactElement => {
  const questionnaireState = useSelector<RootState, IQuestionnaireState>((state: RootState) => state.questionnaireState)
  const { app } = questionnaireState
  const { questionnaire } = useSelector<RootState, IProjectBriefState>((state: RootState) => state.projectBriefState)

  return (
    <>
      {!_.isNull(questionnaire.approval) && _.isEqual(questionnaire.approval.status, QUESTIONNAIRE_STATUS.FAILED) && (
        <WppBanner id="reject-banner" type="information" show>
          <Trans
            values={{
              appName: app!.appName,
            }}
          >
            review.reject.banner.title
          </Trans>
        </WppBanner>
      )}
    </>
  )
}

export default RejectBanner
