// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HoojJ{display:flex;flex-direction:column;height:100%;justify-content:space-between}.HoojJ .zCgzJ{display:flex;gap:12px;flex-direction:column;padding:32px 0px}.HoojJ .zCgzJ .LAQS5{display:flex;justify-content:flex-end}.HoojJ .tvPBd{display:flex;gap:8px;margin-bottom:16px}.HoojJ .tvPBd .YfGh6{font-weight:600}.Erybf{display:flex;align-items:center;gap:8px}.N5GSf::part(message){font-size:14px}`, "",{"version":3,"sources":["webpack://./src/app/components/review/reviewers/reviewersList/ReviewersList.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,6BAAA,CAEA,cACE,YAAA,CACA,QAAA,CACA,qBAAA,CACA,gBAAA,CAEA,qBACE,YAAA,CACA,wBAAA,CAIJ,cACE,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,qBACE,eAAA,CAKN,OACE,YAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,cAAA","sourcesContent":[".modalBody {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n\n  .modalActions {\n    display: flex;\n    gap: 12px;\n    flex-direction: column;\n    padding: 32px 0px;\n\n    .btnClose {\n      display: flex;\n      justify-content: flex-end;\n    }\n  }\n\n  .requesterInfo {\n    display: flex;\n    gap: 8px;\n    margin-bottom: 16px;\n\n    .requesterName {\n      font-weight: 600;\n    }\n  }\n}\n\n.modelHeader {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.inlineMessage::part(message) {\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": `HoojJ`,
	"modalActions": `zCgzJ`,
	"btnClose": `LAQS5`,
	"requesterInfo": `tvPBd`,
	"requesterName": `YfGh6`,
	"modelHeader": `Erybf`,
	"inlineMessage": `N5GSf`
};
export default ___CSS_LOADER_EXPORT___;
