import { WppEmptyError, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'

import styles from 'buildingBlocks/error/Error.module.scss'

interface IErrorProps {
  title: string
  description: string
}

/**
 * Error component
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.description
 */
const ErrorComponent: React.FC<IErrorProps> = ({ title, description }: IErrorProps): React.ReactElement => {
  return (
    <WppGrid container className={styles.container} rowSpacing={2}>
      <WppGrid item all={8} className={styles.items}>
        <WppEmptyError />
        <div className={styles.detailsContainer}>
          <WppTypography type="m-strong" tag="p">
            {title}
          </WppTypography>
          <WppTypography type="m-body">{description}</WppTypography>
        </div>
      </WppGrid>
    </WppGrid>
  )
}

export default ErrorComponent
