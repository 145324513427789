// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k2Odk{position:relative;padding:4px;border-radius:16px;top:-4px;border:2px solid var(--wpp-primary-color-300);left:16px}.k2Odk .FfTQf{position:relative}.k2Odk .FfTQf .d8vo6{position:absolute;border:2px solid var(--wpp-primary-color-300);width:14px;height:14px;left:-6px;bottom:-6px;border-top:0px;border-right:0px;background-color:var(--wpp-white-color);z-index:1;clip-path:polygon(0 0, 100% 100%, 0 100%)}.k2Odk .FfTQf .pFG3b{position:relative;z-index:2}.RdPAK{margin-left:16px}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldComment/FormFieldCommentIcon/FormFieldCommentIcon.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,QAAA,CACA,6CAAA,CACA,SAAA,CAEA,cACE,iBAAA,CACA,qBACE,iBAAA,CACA,6CAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,WAAA,CACA,cAAA,CACA,gBAAA,CACA,uCAAA,CACA,SAAA,CACA,yCAAA,CAEF,qBACE,iBAAA,CACA,SAAA,CAKN,OACE,gBAAA","sourcesContent":[".avatarContainer {\n  position: relative;\n  padding: 4px;\n  border-radius: 16px;\n  top: -4px;\n  border: 2px solid var(--wpp-primary-color-300);\n  left: 16px;\n\n  .avatar {\n    position: relative;\n    .arrow {\n      position: absolute;\n      border: 2px solid var(--wpp-primary-color-300);\n      width: 14px;\n      height: 14px;\n      left: -6px;\n      bottom: -6px;\n      border-top: 0px;\n      border-right: 0px;\n      background-color: var(--wpp-white-color);\n      z-index: 1;\n      clip-path: polygon(0 0, 100% 100%, 0 100%);\n    }\n    .avatarGroup {\n      position: relative;\n      z-index: 2;\n    }\n  }\n}\n\n.chatMessageIcon {\n  margin-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarContainer": `k2Odk`,
	"avatar": `FfTQf`,
	"arrow": `d8vo6`,
	"avatarGroup": `pFG3b`,
	"chatMessageIcon": `RdPAK`
};
export default ___CSS_LOADER_EXPORT___;
