import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import formStyles from 'app/components/form/Form.module.scss'
import styles from 'app/components/form/formField/formFieldAutoComplete/FormFieldAutoComplete.module.scss'
import SelectedValues from 'app/components/form/formField/formFieldAutoComplete/SelectedValues'
import { WppAutocomplete, WppAvatar, WppListItem } from 'buildingBlocks'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldAutoComplete from 'interfaces/field/IFieldAutoComplete'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import AxiosService from 'lib/AxiosService'
import { AutocompleteChangeEventDetail, LoadMoreHandler, WppAutocompleteCustomEvent } from 'utils/types'

interface IFormFieldAutocompleteProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current element value*/
  handleChange: (event: IFieldChangeEvent) => void
}

const getLabel = (inputResponse: any, input: string[] = []): string => {
  let label = ''

  input.forEach((data: string) => {
    label = _.isEmpty(label) ? inputResponse[data] : `${label} ${inputResponse[data]}`
  })

  return label
}

const generateInfiniteResults = async (
  infiniteSearchValueTrimmed: any,
  pageNumber: number,
  autoComplete: IFieldAutoComplete | undefined,
  apiURL: string = '',
  accessToken: string = '',
  tenantId: string = '',
) => {
  if (_.isEmpty(accessToken) || _.isEmpty(apiURL) || _.isUndefined(autoComplete)) return
  const { itemsPerPage, keys, orderBy } = autoComplete
  const { id, label, subLabel, displayLabel, avatarUrl, page } = keys

  const axiosService = new AxiosService(accessToken)
  let requestUrl = `${apiURL}?filter%5Bsearch%5D=${infiniteSearchValueTrimmed}&${page}=${pageNumber}`

  if (itemsPerPage) {
    requestUrl = `${requestUrl}&${keys.itemsPerPage}=${itemsPerPage}`
  }

  if (orderBy) {
    requestUrl = `${requestUrl}&orderBy=${orderBy}`
  }

  const Result: any = await axiosService.get(requestUrl, tenantId)

  const Response = _.isArray(Result.data.data) ? Result.data.data : []

  return Response.map((data: any) => {
    return {
      id: data[id],
      label: getLabel(data, label),
      subLabel: getLabel(data, subLabel),
      displayLabel: getLabel(data, displayLabel),
      avatarUrl: avatarUrl ? data[avatarUrl] : null,
    }
  })
}

/**
 * Create autocomplete element
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldAutocomplete: React.FC<IFormFieldAutocompleteProps> = ({
  field,
  handleChange,
}: IFormFieldAutocompleteProps): React.ReactElement => {
  const { disabled, value, messageType, errorMessage, fieldConfig } = field
  const { placeholder, accessToken, apiUrl, multi, autoComplete, tenantId } = fieldConfig

  const Value: any = !value ? [] : value

  const isInfiniteFirstLoadSkipped = useRef(false)
  const infiniteLoadMoreTimer = useRef<ReturnType<typeof setTimeout>>()
  const [infiniteSearchPage, setInfiniteSearchPage] = useState(1)
  const [isSearchingInfinite, setIsSearchingInfinite] = useState(true)
  const [infiniteSearchValue, setInfiniteSearchValue] = useState('')
  const [infiniteSearchResults, setInfiniteSearchResults] = useState<any[]>([])

  const infiniteSearchValueTrimmed = infiniteSearchValue.trim()

  const infiniteLoadMoreTimerFunc = (page: number, resolve: (value: void | PromiseLike<void>) => void) => {
    infiniteLoadMoreTimer.current = setTimeout(async () => {
      infiniteLoadMoreTimer.current = undefined
      const Users = await generateInfiniteResults(
        infiniteSearchValueTrimmed,
        page,
        autoComplete,
        apiUrl,
        accessToken,
        tenantId,
      )
      setInfiniteSearchResults(current => [...current, ...Users])
      setInfiniteSearchPage(page)

      resolve()
    }, 1000)
  }

  const infiniteSearchLoadMore: LoadMoreHandler = () =>
    new Promise(resolve => {
      const page = infiniteSearchResults.length ? infiniteSearchPage + 1 : infiniteSearchPage
      infiniteLoadMoreTimerFunc(page, resolve)
    })

  // Load infinite options based on search
  useEffect(() => {
    if (_.isEmpty(infiniteSearchValueTrimmed)) return

    if (isInfiniteFirstLoadSkipped.current) {
      if (infiniteLoadMoreTimer.current) {
        clearTimeout(infiniteLoadMoreTimer.current)
        infiniteLoadMoreTimer.current = undefined
      }

      setIsSearchingInfinite(true)

      const timer = setTimeout(async () => {
        const Users = await generateInfiniteResults(infiniteSearchValueTrimmed, 0, autoComplete, apiUrl, accessToken)
        setInfiniteSearchResults(() => [...Users])
        setInfiniteSearchPage(0)
        setIsSearchingInfinite(false)
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      isInfiniteFirstLoadSkipped.current = true
    }
  }, [infiniteSearchValueTrimmed, apiUrl, accessToken, autoComplete])

  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <WppAutocomplete
          name={fieldConfig.text}
          infinite
          loading={!_.isEmpty(infiniteSearchValueTrimmed) && isSearchingInfinite}
          placeholder={placeholder}
          value={Value}
          onWppChange={(event: WppAutocompleteCustomEvent<AutocompleteChangeEventDetail>) => {
            handleChange({
              field,
              value: event.detail.value,
            })
          }}
          onWppSearchValueChange={e => setInfiniteSearchValue(e.detail)}
          id={`autocomplete-${field.id}`}
          loadMore={infiniteSearchLoadMore}
          disabled={disabled}
          multiple={multi}
          simpleSearch
          required={field.isRequired}
          type="extended"
          className={_.isEqual(messageType, 'error') ? '' : formStyles.customStyleDefaultMessage}
          message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
          messageType={messageType}
        >
          {infiniteSearchResults.map((option: IOption) => (
            <WppListItem key={option.id} value={option} label={option.label}>
              <div className={styles.secondary} slot="label">
                {option.label}
              </div>
              {option.subLabel && (
                <div className={styles.secondary} slot="caption">
                  {option.subLabel}
                </div>
              )}
              {!_.isNull(autoComplete?.keys?.avatarUrl) && (
                <WppAvatar size="s" src={option.avatarUrl} slot="left" name={option.label} />
              )}
            </WppListItem>
          ))}
          <SelectedValues
            values={Value}
            disabled={disabled}
            showAvatar={!_.isNull(autoComplete?.keys?.avatarUrl)}
            onCloseClick={(selectedValue: any) => {
              const UpdatedValue = Value.filter((option: IOption) => !_.isEqual(option.id, selectedValue))
              handleChange({
                field,
                value: UpdatedValue,
              })
            }}
          />
        </WppAutocomplete>
      </div>
    </div>
  )
}

export default FormFieldAutocomplete
