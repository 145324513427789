// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._YMgU{margin-bottom:24px;padding:var(--wpp-topbar-with-app-padding, 8px 0);display:flex;flex-direction:column;justify-content:center;position:sticky;top:var(--wpp-topbar-offset-top, 64px);z-index:10;background-color:var(--wpp-grey-color-000);min-height:60px}._YMgU .eOG8f{width:100%;position:absolute;bottom:0px}.TKlgl{display:flex;align-items:center;justify-content:space-between;padding:12px 8px}`, "",{"version":3,"sources":["webpack://./src/app/components/header/Header.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,iDAAA,CACA,YAAA,CAEA,qBAAA,CAEA,sBAAA,CAEA,eAAA,CACA,sCAAA,CACA,UAAA,CACA,0CAAA,CACA,eAAA,CAEA,cACE,UAAA,CACA,iBAAA,CACA,UAAA,CAIJ,OACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,gBAAA","sourcesContent":[".headerContainer {\n  margin-bottom: 24px;\n  padding: var(--wpp-topbar-with-app-padding, 8px 0);\n  display: flex;\n  -ms-flex-direction: column;\n  flex-direction: column;\n  -ms-flex-pack: justify;\n  justify-content: center;\n  position: -webkit-sticky;\n  position: sticky;\n  top: var(--wpp-topbar-offset-top, 64px);\n  z-index: 10;\n  background-color: var(--wpp-grey-color-000);\n  min-height: 60px;\n\n  .divider {\n    width: 100%;\n    position: absolute;\n    bottom: 0px;\n  }\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `_YMgU`,
	"divider": `eOG8f`,
	"header": `TKlgl`
};
export default ___CSS_LOADER_EXPORT___;
