import { Trans } from 'react-i18next'

import styles from 'app/components/confirmation/Confirmation.module.scss'
import { WppModal, WppButton } from 'buildingBlocks'

interface IConfirmationProps {
  /** If modal is open */
  isOpen: boolean
  /** Handle Save Event */
  handlePrimaryAction: () => void
  /** Handle Cancel Event */
  handleSecondaryAction: () => void
  /** Modal title */
  title: string
  /** Modal body */
  body: string
  /** Primary button text */
  btnPrimaryText?: string
  /** Secondary button text */
  btnSecondaryText?: string
}

/**
 * Confirmation Modal component
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.body
 * @param {boolean} props.isOpen
 * @param {handlePrimaryAction} props.handlePrimaryAction
 * @param {handleSecondaryAction} props.handleSecondaryAction
 * @param {string} props.btnPrimaryText
 * @param {string} props.btnSecondaryText
 */
const Confirmation: React.FC<IConfirmationProps> = ({
  title,
  body,
  isOpen,
  handlePrimaryAction,
  handleSecondaryAction,
  btnPrimaryText,
  btnSecondaryText,
}: IConfirmationProps): React.ReactElement => (
  <WppModal open={isOpen}>
    <h3 slot="header">{title}</h3>
    <p slot="body">{body}</p>
    <div slot="actions" className={styles.actions}>
      <WppButton className={styles.btnSave} variant="primary" size="s" onClick={handlePrimaryAction}>
        {btnPrimaryText ?? <Trans>app.button.save</Trans>}
      </WppButton>
      <WppButton variant="secondary" size="s" onClick={handleSecondaryAction}>
        {btnSecondaryText ?? <Trans>app.button.cancel</Trans>}
      </WppButton>
    </div>
  </WppModal>
)

export default Confirmation
