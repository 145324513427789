// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rJnqt{display:flex;gap:8px}.d90xP{flex-direction:column}.b82vy{flex-direction:row}.kViCH{display:flex;flex-direction:column}.t6Jn5{display:flex;flex-direction:column}.t6Jn5 .B8xD5{display:flex;align-items:center}.sx716::part(circle){min-width:20px;min-height:20px}.sx716::part(content){height:auto}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldRadio/FormFieldRadio.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,OAAA,CAGF,OACE,qBAAA,CAGF,OACE,kBAAA,CAGF,OACE,YAAA,CACA,qBAAA,CAEF,OACE,YAAA,CACA,qBAAA,CAEA,cACE,YAAA,CACA,kBAAA,CAKF,qBACE,cAAA,CACA,eAAA,CAEF,sBACE,WAAA","sourcesContent":[".radioGroup {\n  display: flex;\n  gap: 8px;\n}\n\n.displayRadioGroupVertical {\n  flex-direction: column;\n}\n\n.displayRadioGroupHorizontal {\n  flex-direction: row;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n}\n.radioContainer {\n  display: flex;\n  flex-direction: column;\n\n  .radio {\n    display: flex;\n    align-items: center;\n  }\n}\n\n.radioButton {\n  &::part(circle) {\n    min-width: 20px;\n    min-height: 20px;\n  }\n  &::part(content) {\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": `rJnqt`,
	"displayRadioGroupVertical": `d90xP`,
	"displayRadioGroupHorizontal": `b82vy`,
	"container": `kViCH`,
	"radioContainer": `t6Jn5`,
	"radio": `B8xD5`,
	"radioButton": `sx716`
};
export default ___CSS_LOADER_EXPORT___;
