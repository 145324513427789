import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import IAppContextState from 'store/interfaces/IAppContextState'
import SharedHelper from 'utils/SharedHelper'
import { FullscreenAppContext } from 'utils/types'

const defaultAppContextState: IAppContextState = {
  appInstanceId: '',
  homeUrl: '',
  itemId: '',
  projectId: '',
  projectName: '',
  tenantId: '',
  userEmail: '',
  loading: true,
  permissions: [],
  tenantLogo: null,
  isAppEditable: false,
}

export const appContextSlice = createSlice({
  name: 'appContext',
  initialState: defaultAppContextState,
  reducers: {
    setAppContext: (state: IAppContextState, action: PayloadAction<FullscreenAppContext>) => {
      return {
        ...state,
        ...SharedHelper.getAppState(action.payload),
        loading: false,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAppContext } = appContextSlice.actions

export default appContextSlice.reducer
