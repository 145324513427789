import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import { WppSelect, WppListItem } from 'buildingBlocks'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'

interface IFormFieldSelectInputProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
  /** Element id */
  id?: string
}

/**
 * Create select field
 * @param {object} props
 * @param {IField} props.field
 * @param {IField} props.id
 * @param {Function} props.handleChange
 */
const FormFieldSelect: React.FC<IFormFieldSelectInputProps> = ({
  field,
  handleChange,
  id,
}: IFormFieldSelectInputProps): React.ReactElement => {
  const { disabled, messageType, errorMessage, fieldConfig, value } = field
  const { placeholder, options, multi } = fieldConfig

  return (
    <WppSelect
      onWppChange={(event: CustomEvent) => {
        handleChange({
          field,
          value: event.detail.value,
        })
      }}
      id={id ?? `form-field-select-${field.id}`}
      placeholder={placeholder}
      value={value}
      withSearch
      disabled={disabled}
      type={multi ? 'multiple' : 'single'}
      messageType={messageType}
      message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
      className={_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage}
    >
      {options.map((option: IOption) => (
        <WppListItem value={option.id} key={option.id}>
          <p slot="label">{option.label}</p>
        </WppListItem>
      ))}
    </WppSelect>
  )
}

export default FormFieldSelect
