import React from 'react'

import styles from 'app/components/spinner/Spinner.module.scss'
import { WppSpinner } from 'buildingBlocks'

// Spinner Component
const Spinner: React.FC = (): React.ReactElement => {
  return (
    <div className={styles.container}>
      <WppSpinner size="l" color="var(--wpp-primary-color-500)" />
    </div>
  )
}

export default Spinner
