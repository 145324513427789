import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/formField/formFieldDescription/FormFieldDescription.module.scss'
import { WppTypography } from 'buildingBlocks'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'

interface IFormFieldDescriptionProps {
  /** IField interface */
  field: IField
  /** IOption interface */
  option: IOption
}

/**
 * Create counter field
 * @param {object} props
 * @param {IField} props.field
 * @param {IOption} props.option
 */
const FormFieldDescription: React.FC<IFormFieldDescriptionProps> = ({
  option,
  field,
}: IFormFieldDescriptionProps): React.ReactElement => {
  if (!option.description || !_.isEqual(option.id, field.value)) {
    return <></>
  }

  return (
    <WppTypography className={styles.description} type="s-body">
      <span
        dangerouslySetInnerHTML={{
          __html: option.description,
        }}
      />
    </WppTypography>
  )
}

export default FormFieldDescription
