import { WppTextareaInputCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import styles from 'app/components/form/Form.module.scss'
import { WppTextareaInput } from 'buildingBlocks'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { TextareaInputChangeEventDetail } from 'utils/types'

interface IFormFieldTextareaProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has IFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create textarea field
 * @param {object} props
 * @param {IField} props.field
 * @param {Function} props.handleChange
 */
const FormFieldTextarea: React.FC<IFormFieldTextareaProps> = ({
  field,
  handleChange,
}: IFormFieldTextareaProps): React.ReactElement => {
  const { disabled, fieldConfig, value, messageType, errorMessage } = field
  const { placeholder, charactersLimit } = fieldConfig

  let charactersLimitProps = {}

  if (!_.isUndefined(charactersLimit) && !disabled) {
    charactersLimitProps = {
      charactersLimit,
      warningThreshold: charactersLimit - 10,
    }
  }

  const handleInputChange = (event: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => {
    handleChange({
      field,
      value: event.detail.value,
    })
  }

  return (
    <div>
      <WppTextareaInput
        slot="form-element"
        size="m"
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        id={`textarea-${field.id}`}
        messageType={messageType}
        message={_.isEqual(messageType, 'error') ? errorMessage : fieldConfig.help}
        className={classNames(_.isEqual(messageType, 'error') ? '' : styles.customStyleDefaultMessage, styles.textarea)}
        onWppChange={handleInputChange}
        {...charactersLimitProps}
      />
    </div>
  )
}

export default FormFieldTextarea
