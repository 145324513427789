import { FullscreenAppCustomProps } from '@wppopen/core'
import { OsProvider } from '@wppopen/react'
import i18n from 'i18next'
import { StrictMode } from 'react'
import { initReactI18next } from 'react-i18next'
import { Provider } from 'react-redux'
import '@platform-ui-kit/components-library/dist/platform-ui-kit/global.css'

import { App } from 'app/App'
import translationEN from 'lang/en.json'
import { store } from 'store'

const resources = {
  en: {
    translation: translationEN,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export const Root = (props: FullscreenAppCustomProps) => {
  return (
    <StrictMode>
      <OsProvider {...props}>
        <Provider store={store}>
          <App />
        </Provider>
      </OsProvider>
    </StrictMode>
  )
}
