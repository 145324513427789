import { WppTooltip as WppTooltipObject } from '@platform-ui-kit/components-library-react'
import React from 'react'

interface IWppTooltipProps {
  /** Tooltip text */
  component: React.ReactElement
  /** Allow HTML */
  allowHTML?: boolean
  /** Tooltip text */
  text?: string
  /** Tooltip content */
  tooltipHTMLContent?: React.ReactElement
  /** Show tooltip */
  showTooltip?: boolean
}

/**
 * Wpp Custom tooltip component
 * @param {object} props
 * @param {string} props.text
 * @param {React.ReactElement} props.component
 * @param {boolean} props.allowHTML
 * @param {React.ReactElement} props.tooltipHTMLContent
 * @param {boolean} props.showTooltip
 */
const WppTooltip: React.FC<IWppTooltipProps> = ({
  text,
  component,
  tooltipHTMLContent,
  allowHTML = false,
  showTooltip = true,
}: IWppTooltipProps): React.ReactElement => {
  if (!showTooltip) {
    return component
  }

  if (allowHTML) {
    return (
      <WppTooltipObject config={{ allowHTML: true }}>
        {component}
        <div slot="tooltip-content">{tooltipHTMLContent}</div>
      </WppTooltipObject>
    )
  }

  return <WppTooltipObject text={text}>{component}</WppTooltipObject>
}

export default WppTooltip
