import { HeadingLevel, Paragraph, Document, AlignmentType, ExternalHyperlink, TextRun } from 'docx'
import { TFunction } from 'i18next'
import _ from 'lodash'

import { DOCX_FONT_FAMILY_NAME } from 'config/constants'
import { FIELD_TYPES } from 'config/enums'
import IApp from 'interfaces/app/IApp'
import ICategory from 'interfaces/category/ICategory'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IRepeatableField from 'interfaces/field/repeatable/IRepeatableField'
import IFile from 'interfaces/file/IFile'
import IForm from 'interfaces/form/IForm'
import PDFHelper from 'utils/form/PDFHelper'
import FormFieldValidation from 'utils/formField/FormFieldValidation'

export default class DocHelper {
  /**
   * Get field value
   * @param {IField} field
   * @returns {Paragraph[]}
   */
  public static getFieldValue(field: IField): Paragraph[] {
    let fieldValue: Paragraph[] = []
    const value = PDFHelper.getFieldValue(field)
    if (FormFieldValidation.isEmptyField(field)) {
      return [this.createFieldValue('-')]
    }

    switch (field.type) {
      case FIELD_TYPES.FILE_UPLOAD: {
        if (_.isArray(value)) {
          const data: Paragraph[] = value.map(({ file }: IFile) => this.createHyperLink(file.name, file.url))
          fieldValue = _.concat(fieldValue, data)
        }
        break
      }
      case FIELD_TYPES.TEXT_INPUT:
      case FIELD_TYPES.TEXT_AREA:
      case FIELD_TYPES.CURRENCY:
      case FIELD_TYPES.DATE_PICKER: {
        fieldValue.push(this.createFieldValue(value))
        break
      }
      case FIELD_TYPES.RADIO: {
        const option: undefined | IOption = PDFHelper.getRadioValue(field)
        fieldValue.push(this.createFieldValue(!_.isUndefined(option) ? option.label : '-'))
        break
      }
      case FIELD_TYPES.CHECKBOX: {
        if (_.isArray(value)) {
          const data: Paragraph[] = _.orderBy(value, ['id'], 'asc').map(
            (option: IOption): Paragraph => this.createBulletValue(option.label),
          )
          fieldValue = _.concat(fieldValue, data)
        }
        break
      }
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.MARKET:
      case FIELD_TYPES.USER:
      case FIELD_TYPES.USER_MENTION:
      case FIELD_TYPES.AUTOCOMPLETE: {
        if (_.isArray(value)) {
          const data: Paragraph[] = value.map((val: string): Paragraph => this.createBulletValue(val))
          fieldValue = _.concat(fieldValue, data)
        }
        break
      }
    }
    return fieldValue
  }

  /**
   * Get form fields
   * @param {IField[]} fields
   * @returns {Paragraph[]}
   */
  public static getFields(fields: IField[]): Paragraph[] {
    let data: Paragraph[] = []
    fields.forEach((field: IField) => {
      if (field.isHidden) return
      if (_.gt(field.maxRepeatableAmount, 0)) {
        field.repeatableFields.forEach((rField: IRepeatableField, index: number) => {
          const updatedField: IField = { ...field, value: rField.value }
          const fieldLabel = !field.fieldConfig.text ? '' : `${field.fieldConfig.text} ${index + 1}`
          if (fieldLabel) data.push(this.createFieldHeading(fieldLabel))
          data = _.concat(data, this.getFieldValue(updatedField))
        })
      } else {
        if (field.fieldConfig.text) data.push(this.createFieldHeading(field.fieldConfig.text))
        data = _.concat(data, this.getFieldValue(field))
      }
      data = _.concat(data, this.getFields(field.children))
    })
    return data
  }

  /**
   * Get forms
   * @param {IForm[]} forms
   * @returns {Paragraph[]}
   */
  public static getForms(forms: IForm[]): Paragraph[] {
    let data: Paragraph[] = []

    forms.forEach((form: IForm) => {
      if (!_.isEmpty(form.name)) {
        data.push(this.createFormHeading(form.name))
      }
      data = _.concat(data, this.getFields(form.fields))
    })
    return data
  }

  /**
   * Get categories
   * @param {ICategory[]} categories
   * @param {string} projectQuestionnaireId
   * @returns {Paragraph[]}
   */
  public static getCategories(categories: ICategory[], projectQuestionnaireId: string, t: TFunction): Paragraph[] {
    let data: Paragraph[] = []
    categories.forEach((category: ICategory, index: number) => {
      data.push(this.createCategoryHeading(category.name))
      if (_.isEqual(index, 0)) {
        data.push(this.createFieldHeading(t('form.briefIdNumber')))
        data.push(this.createFieldValue(projectQuestionnaireId))
      }
      data = _.concat(data, this.getForms(category.forms))
    })
    return data
  }

  /**
   * Create document
   * @param {IApp} app
   * @param {string} projectQuestionnaireId
   * @returns {Document}
   */
  public static createDoc(app: IApp, projectQuestionnaireId: string, t: TFunction): Document {
    const document = new Document({
      title: app.appName,
      styles: {
        paragraphStyles: [
          {
            id: 'briefName',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 40,
            },
          },
          {
            id: 'category',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 32,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'form',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 28,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'field',
            run: {
              bold: true,
              font: DOCX_FONT_FAMILY_NAME,
              size: 24,
            },
            paragraph: {
              spacing: {
                before: 200,
              },
            },
          },
          {
            id: 'text',
            run: {
              font: DOCX_FONT_FAMILY_NAME,
              size: 24,
            },
          },
        ],
      },
      sections: [
        {
          children: [
            new Paragraph({
              text: app.appName,
              heading: HeadingLevel.HEADING_1,
              style: 'briefName',
              alignment: AlignmentType.CENTER,
            }),
            ...this.getCategories(app.categories, projectQuestionnaireId, t),
          ],
        },
      ],
    })
    return document
  }

  /**
   * Create category heading text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createCategoryHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
      style: 'category',
    })
  }

  /**
   * Create form text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFormHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
      style: 'form',
    })
  }

  /**
   * Create field heading text
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFieldHeading(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_3,
      style: 'field',
    })
  }

  /**
   * Create field value
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createFieldValue(text: string = ''): Paragraph {
    return new Paragraph({
      text,
      style: 'text',
    })
  }

  /**
   * Create bullet value
   * @param {string} text
   * @returns {Paragraph}
   */
  public static createBulletValue(text: string = ''): Paragraph {
    return new Paragraph({
      text: text,
      style: 'text',
      bullet: {
        level: 0,
      },
    })
  }

  /**
   * Create hyperlink
   * @param {string} text
   * @param {string} link
   * @returns {Paragraph}
   */
  public static createHyperLink(text: string = '', link: string = ''): Paragraph {
    return new Paragraph({
      bullet: {
        level: 0,
      },
      style: 'text',
      children: [
        new ExternalHyperlink({
          children: [
            new TextRun({
              text,
              style: 'Hyperlink',
            }),
          ],
          link,
        }),
      ],
    })
  }
}
