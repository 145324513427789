// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.omzWo{padding:0px 6px;display:flex;flex-direction:column;gap:12px}.omzWo .D3CSW{display:flex;align-items:center;gap:8px;flex-wrap:wrap}.omzWo .PGYMv{display:flex;justify-content:flex-end;gap:4px}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldComment/formFieldCommentEditor/FormFieldCommentEditor.module.scss"],"names":[],"mappings":"AAEA,OACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,cACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,cAAA,CAGF,cACE,YAAA,CACA,wBAAA,CACA,OAAA","sourcesContent":["@import '@platform-ui-kit/components-library/dist/collection/scrollbar';\n\n.commentEditor {\n  padding: 0px 6px;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  .userInfo {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    flex-wrap: wrap;\n  }\n\n  .actions {\n    display: flex;\n    justify-content: flex-end;\n    gap: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentEditor": `omzWo`,
	"userInfo": `D3CSW`,
	"actions": `PGYMv`
};
export default ___CSS_LOADER_EXPORT___;
