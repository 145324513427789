import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TOAST_DURATION } from 'config/constants'
import { TOAST_MESSAGE_TYPES } from 'config/enums'
import IAlertState from 'store/interfaces/IAlertState'

const defaultAlertState: IAlertState = {
  open: false,
  message: '',
  type: TOAST_MESSAGE_TYPES.SUCCESS,
  duration: TOAST_DURATION,
  header: '',
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState: defaultAlertState,
  reducers: {
    showAlert: (state: IAlertState, action: PayloadAction<IAlertState>): IAlertState => {
      return {
        ...state,
        ...action.payload,
        open: true,
      }
    },
    hideAlert: (): IAlertState => {
      return defaultAlertState
    },
    setErrorAlert: (state: IAlertState, action: PayloadAction<string>): IAlertState => {
      return {
        ...state,
        open: true,
        message: action.payload,
        type: TOAST_MESSAGE_TYPES.ERROR,
      }
    },
    showErrorAlert: (state: IAlertState): IAlertState => {
      return {
        ...state,
        open: true,
        message: 'There is a server error. Please try again later or contact customer support if the problem persists.',
        type: TOAST_MESSAGE_TYPES.ERROR,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { showAlert, showErrorAlert, hideAlert, setErrorAlert } = alertSlice.actions

export default alertSlice.reducer
