// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fUN4a{display:flex;align-items:center;gap:16px}.o_b34{display:flex;flex-direction:column}.nvbWa::part(content){background-color:var(--wpp-grey-color-500)}`, "",{"version":3,"sources":["webpack://./src/buildingBlocks/userAvatar/UserAvatar.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,OACE,YAAA,CACA,qBAAA,CAGF,sBACE,0CAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n.userInfo {\n  display: flex;\n  flex-direction: column;\n}\n\n.userAvatar::part(content) {\n  background-color: var(--wpp-grey-color-500);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fUN4a`,
	"userInfo": `o_b34`,
	"userAvatar": `nvbWa`
};
export default ___CSS_LOADER_EXPORT___;
