import { WppRadioGroupCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import FormFieldChildren from 'app/components/form/formField/formFieldChildren'
import FormFieldDescription from 'app/components/form/formField/formFieldDescription'
import FormFieldMessage from 'app/components/form/formField/formFieldMessage'
import styles from 'app/components/form/formField/formFieldRadio/FormFieldRadio.module.scss'
import Radio from 'app/components/form/formField/formFieldRadio/Helper/Radio'
import { WppRadioGroup } from 'buildingBlocks'
import { FIELD_ORIENTATION } from 'config/enums'
import IOption from 'interfaces/common/IOption'
import IField from 'interfaces/field/IField'
import IFieldChangeEvent from 'interfaces/field/IFieldChangeEvent'
import { RadioGroupChangeEvent } from 'utils/types'

interface IFormFieldRadioProps {
  /** IField interface */
  field: IField
  /** handle input change event. It has InputFieldChangeEvent interface which have IField interface, and current field value*/
  handleChange: (event: IFieldChangeEvent) => void
}

/**
 * Create radio field
 * @param {object} props
 * @param {IField} props.field
 * @param {(event: IFieldChangeEvent) => void} props.handleChange
 */
const FormFieldRadio: React.FC<IFormFieldRadioProps> = ({
  field,
  handleChange,
}: IFormFieldRadioProps): React.ReactElement => {
  const { fieldConfig, value } = field
  const { options, orientation } = fieldConfig

  return (
    <>
      <WppRadioGroup
        className={classNames(
          {
            [styles.displayRadioGroupVertical]: _.isEqual(orientation, FIELD_ORIENTATION.VERTICAL),
            [styles.displayRadioGroupHorizontal]: _.isEqual(orientation, FIELD_ORIENTATION.HORIZONTAL),
          },
          styles.radioGroup,
        )}
        onWppChange={(event: WppRadioGroupCustomEvent<RadioGroupChangeEvent>) => {
          handleChange({
            field,
            value: event.detail.value,
          })
        }}
        value={value}
        id={`radio-group-${field.id}`}
      >
        {options.map((option: IOption) => (
          <div className={styles.container} key={option.id}>
            <div className={styles.radioContainer}>
              <Radio field={field} handleChange={handleChange} option={option} />
              <FormFieldDescription
                field={{
                  ...field,
                  value,
                }}
                option={option}
              />
            </div>
            <FormFieldChildren
              field={{
                ...field,
                value,
              }}
              optionValue={option.id}
              handleChange={handleChange}
            />
          </div>
        ))}
      </WppRadioGroup>

      <FormFieldMessage field={field} />
    </>
  )
}

export default FormFieldRadio
